// Librairies principales
import { createApp } from 'vue'
import App from './App.vue'
import store from '@shared/store'
import api from '@shared/services/api'
import i18n from '@shared/i18n'
import * as Router from '@shared/router'

import Layout from '@admin/Layout.vue'
import createMyVuetify from '@admin/plugins/vuetify'



import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
// Note : I'm experimenting a lot with Vuetify, so this is a mess, yeah ....
// We will clean this later, it's no big deal


// Styles
import '@shared/assets/scss/main.scss' // <= CSS partagé entre toutes les apps
import '@admin/assets/scss/main.scss' // <= CSS par défaut de l'app admin
import '@prescriber/assets/scss/main.scss' // <= CSS par défaut de l'app prescriber
import '@customer/app-prescriber/assets/scss/main.scss' // <= CSS surchargé pour le client, pour l'app prescriber


// Imports de composants un peu en vrac (mais en async), peut être amélio aussi (?)
import sharedComponents from '@shared/components/imports.js'
import customerSharedComponents from '@customer/shared/components/imports.js'
import appComponents from '@prescriber/components/imports.js'
import appCustomerComponents from '@customer/app-prescriber/components/imports.js'


// Bootstrap de l'app :
// - Chargement de la partie locale de la conf (settings propres à cette app par rapport à la base commune)
// - Initialisation de l'API
// - Chargement de la partie BO de la conf (routing, etc)
// - Chargement des composants hypokrat importés
// - Initialisation de VueJS

// Chargement de la partie locale de la conf (settings propres à cette app par rapport à la base commune)
// + environnement => BO_URL, etc
// Remplis donc les stores de baseConfig ET envConfig
store.dispatch('appp/initConfig', {
    appName: 'prescriber',
    targetUserType: 'staff',
}).then(() => {

    // Initialisation de l'API (config de Axios, withCredentials, etc)
    api.initApi();

    // Chargement de la partie BO de la conf (routing, etc)
    store.dispatch('app/initBOConfig').then(() => {

        store.dispatch('app/initI18n');

        // @todo => intégration entre getBOTheme et le theme de vuetify, beaucoup de choses possibles ici !
        // @todo => virer aussi ce code dans un fichier à part
        const vuetify = createMyVuetify(store);

        const app = createApp(App)
        const router = Router.init(Layout);
        // Chargement des composants hypokrat importés.
        sharedComponents.import(app);
        customerSharedComponents.import(app);
        appComponents.import(app);
        appCustomerComponents.import(app);

        
        // Boum !
        app.use(store).use(router).use(i18n).use(vuetify).use(VueTelInput).mount('#app');

        // Register components for later use
        store.dispatch('app/registerComponents', app._context.components)
    });
});